import moment from 'moment';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import {
  addPermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../actions';
import { getApiStatus } from '../../../core/utils';
import InventoryTypeFilter from '../components/InventoryTypeFilter';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import SearchBar from '../../../core/components/SearchBar';
import InventoryDetail from './InventoryDetail';
import InventoryStockLevel from '../components/InventoryStockField';
import {
  selectInventory,
  selectInventoryTypes,
  selectPage,
  selectPageSize,
  selectTotal,
  selectInventoryInEdit,
  selectFromDate,
  selectUntilDate,
  selectTypeFilter,
  selectStockUpdateStatus,
} from '../reducer';
import { Drawer, DrawerContainer } from '../../../core/components/Drawer';
import UserGroupPermissions from '../../../core/components/UserGroupPermissions';
import Button from '../../../core/components/Button';
import { fetchAlerts } from '../../site-selection/actions';
import { getSiteLabel } from '../../site-selection/helpers';
import { selectSitesBasic } from '../../site-selection/reducer';

const Inventory = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const inventory = useSelector(selectInventory);
  const inventoryTypes = useSelector(selectInventoryTypes);
  const filter = useSelector(selectTypeFilter);
  const total = useSelector(selectTotal);
  const page = useSelector(selectPage);
  const pageSize = useSelector(selectPageSize);
  const sites = useSelector(selectSitesBasic);
  const siteId = useSelector(selectSiteExtId);
  const multiSites = useSelector(selectMultiSiteSelection);
  const [inventoryExpanded, setExpanded] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [inventoryStockLevels, setInventoryStockLevels] = useState(new Map());
  const inventoryInEdit = useSelector(selectInventoryInEdit);
  const inventoryAdded = useSelector((state) => getApiStatus(state, 'ADD_INVENTORY_ITEM'));
  const inventoryEdited = useSelector((state) => getApiStatus(state, 'SAVE_INVENTORY_ITEM'));
  const inventoryDeleted = useSelector((state) => getApiStatus(state, 'DELETE_INVENTORY_ITEM'));
  const inventoryUpdated = useSelector((state) => getApiStatus(state, 'UPDATE_INVENTORY_STOCK'));
  const stockUpdateStatus = useSelector(selectStockUpdateStatus);

  //Search variables
  const fromDate = useSelector(selectFromDate);
  const untilDate = useSelector(selectUntilDate);
  const [searchResults, setSearchResults] = useState(null);
  const [searchResultsDetails, setSearchResultsDetails] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    updateInventory();
  }, [
    filter,
    siteId,
    multiSites,
    fromDate,
    untilDate,
    inventoryAdded,
    inventoryEdited,
    inventoryDeleted,
    inventoryUpdated,
  ]);

  useEffect(() => {
    dispatch(actions.fetchInventoryTypes());
  }, [siteId]);

  useEffect(() => {
    if (stockUpdateStatus != '') {
      updateInventory();
      exitBulkEdit();
      setTimeout(() => {
        dispatch(fetchAlerts());
      }, 2000);
      dispatch(actions.setStockUpdateStatus(''));
    }
  }, [stockUpdateStatus]);

  useEffect(() => {
    setExpanded(false);
    setHighlightedRow(0);
  }, [filter, searchResults?.length]);

  const getInventoryPage = (selectedPage) => {
    dispatch(actions.fetchInventory({ selectedPage }));
  };

  const getPaginationInfo = () => {
    return {
      total: total,
      page: page,
      pageSize: pageSize,
      goToPage: getInventoryPage,
    };
  };

  const clearInventoryInEdit = () => {
    setHighlightedRow(0);
    setExpanded(false);
    dispatch(actions.deleteInventoryInEdit());
  };

  const updateInventory = () => {
    dispatch(
      actions.fetchInventory({
        inventoryType: filter,
        fromDate: fromDate,
        untilDate: untilDate,
      })
    );
  };

  const addInventory = () => {
    let dialogProps = {
      onSubmit: (item) => dispatch(actions.addInventoryItem(item)),
      isNew: true,
      title: intl.formatMessage({ id: 'addInventoryItem' }),
      intl: intl,
    };
    dispatch(showDialog('UPSERT_INVENTORY', dialogProps));
  };

  const getRowClickInfo = () => ({
    callback: (data) => {
      if (!isUpdating) {
        setExpanded(true);
        setHighlightedRow(data._id);
        dispatch(actions.getInventoryItem(data._id));
      }
    },
  });

  const setInventoryStock = (inventoryId, stockLevel) => {
    const newMap = new Map(inventoryStockLevels);
    newMap.set(inventoryId, stockLevel);
    setInventoryStockLevels(newMap);
  };

  const clearInventoryStock = (inventoryId) => {
    const newMap = new Map(inventoryStockLevels);
    if (newMap.delete(inventoryId)) {
      setInventoryStockLevels(newMap);
    }
  };

  const enterBulkEdit = () => {
    setIsUpdating(true);
    clearInventoryInEdit();
  };

  const exitBulkEdit = () => {
    setIsUpdating(false);
    const emptyMap = new Map();
    setInventoryStockLevels(emptyMap);
  };

  const handleStockSubmit = () => {
    const levelsList = [];
    for (let [key, value] of inventoryStockLevels.entries()) {
      levelsList.push({ id: key, number: Number(value) });
    }
    dispatch(actions.updateInventoryStock({ stockLevels: levelsList }));
  };

  const trailDescription = (description) => {
    if (description && description.length > 100) {
      return description.substring(0, 100) + '...';
    }
    return description;
  };

  const initializeDatatable = (initialInventory) => {
    const data = initialInventory.map((i) => {
      return {
        _id: i.id,
        name: i.priority ? (
          <span className="inventory-name">
            <span className="inventory-priority">
              <i className="uil uil-clipboard-notes"></i>
            </span>{' '}
            {trailDescription(i.name)}
          </span>
        ) : (
          trailDescription(i.name)
        ),
        partNumber: i.number,
        description: trailDescription(i.description),
        site: getSiteLabel(sites, i.siteId),
        createdAt: i.createdAt
          ? moment(i.createdAt).format('MM/DD/YYYY')
          : intl.formatMessage({ id: 'notAvailable' }),
        _preferredStockLevel: i.preferredStockLevel,
        _onHand: i.onHand,
        unit: i?.unitOfMeasure || '',
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'partNumber' }) },
      { name: intl.formatMessage({ id: 'description' }) },
      { name: intl.formatMessage({ id: 'site' }) },
      { name: intl.formatMessage({ id: 'createdAt' }) },
      {
        name: intl.formatMessage({ id: 'stockLevel' }),
        cell: {
          component: InventoryStockLevel,
          mixIns: {
            isEditing: isUpdating,
            updateStock: setInventoryStock,
            clearStock: clearInventoryStock,
          },
        },
      },
      { name: intl.formatMessage({ id: 'unit' }) },
    ];

    return {
      data,
      rowClickInfo: getRowClickInfo(),
      headers,
    };
  };

  const drawerControlsConfig = () => {
    return {
      closeFunction: () => {
        clearInventoryInEdit();
      },
    };
  };

  const setSearchMatch = (sResults, sResultsDetails, sText) => {
    setSearchResults(sResults);
    setSearchResultsDetails(sResultsDetails);
    setSearchText(sText);
  };

  const data = searchResults !== null ? searchResults : inventory;

  return (
    <div className="container-fluid">
      <section className="page-main-content inventory">
        <div className="table-buttons">
          <div className="table-filters no-margin">
            <InventoryTypeFilter inventoryTypes={inventoryTypes} selectedFilter={filter} />
            <SearchBar
              intl={intl}
              dataToSearchOn={inventory}
              dataToSearchFor={['name', 'number', 'description']}
              setSearchMatch={setSearchMatch}
              uniqueField="id"
              styles={['data-table-search']}
            />
          </div>
          <div className="page-action-buttons">
            <UserGroupPermissions itemRef={editPermissionRefs.inventory}>
              {isUpdating ? (
                <>
                  <Button onClick={() => exitBulkEdit()} className="button">
                    {intl.formatMessage({ id: 'cancel' })}
                  </Button>
                  <Button
                    onClick={() => handleStockSubmit()}
                    className="button confirm"
                    disabled={inventoryStockLevels?.size <= 0}
                  >
                    {intl.formatMessage({ id: 'saveStockLevels' })}
                  </Button>
                </>
              ) : (
                <Button onClick={() => enterBulkEdit()} className="button">
                  {intl.formatMessage({ id: 'updateStock' })}
                </Button>
              )}
            </UserGroupPermissions>
            <UserGroupPermissions itemRef={addPermissionRefs.inventory}>
              {!isUpdating && (
                <Button onClick={() => addInventory()}>
                  {intl.formatMessage({ id: 'addInventoryItem' })}
                </Button>
              )}
            </UserGroupPermissions>
          </div>
        </div>
        <DrawerContainer>
          <div>
            <DataTable
              paginationInfo={getPaginationInfo()}
              highlightedRow={highlightedRow}
              scrolling
              {...initializeDatatable(data)}
            />
          </div>
          <Drawer expanded={inventoryExpanded && !isUpdating}>
            <div className="inventory-details-wrapper">
              {inventoryInEdit && (
                <InventoryDetail isPage={false} drawerControls={{ ...drawerControlsConfig() }} />
              )}
            </div>
          </Drawer>
        </DrawerContainer>
      </section>
    </div>
  );
};

export default Inventory;
