import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/azure';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { ActionExternalCall, ActionInternalCall } from '../../utils/typing';

export const GET_REPORT = 'GET_REPORT';
export const SET_PERMISSION = 'SET_PERMISSION';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const SET_ANALYTICS_PAGE = 'SET_ANALYTICS_PAGE';
export const GET_HOURLY_COUNTS_REPORT = 'GET_HOURLY_COUNTS_REPORT';

export const fetchReport = (safetyInterval: number, specificReport: string): ActionExternalCall => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchReport,
    action: GET_REPORT,
    data: { query: { specificReport } },
    meta: {
      id: 'id',
      safetyInterval,
      permissionKey: viewPermissionRefs.analytics,
      useManagerSiteId: true,
      options: {
        reportName: specificReport,
      },
    },
  };
};

export const fetchHourlyWashCountsReport = (
  safetyInterval: number,
  specificReport: string
): ActionExternalCall => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchHourlyWashCountsReport,
    action: GET_HOURLY_COUNTS_REPORT,
    data: { query: { specificReport } },
    meta: {
      id: 'id',
      safetyInterval,
      permissionKey: viewPermissionRefs.analytics,
      useManagerSiteId: true,
      options: {
        specificReport,
      },
    },
  };
};

export const setPermissionLevel = (level: string): ActionInternalCall => {
  return { type: SET_PERMISSION, payload: level };
};

export const clearReport = (): ActionInternalCall => {
  return { type: CLEAR_REPORT };
};

export const setAnalyticsPage = (page: string): ActionInternalCall => {
  return { type: SET_ANALYTICS_PAGE, payload: page };
};
