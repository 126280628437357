import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import UserGroupPermissions, {
  viewPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { useAppContext } from '../../../providers/AppProvider';
import { selectSite } from '../../../store/authReducer';

import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { fetchHourlyWashCounts } from '../../wash-counts/actions';
import { selectHourlyWashCounts } from '../../wash-counts/reducer';
import {
  getClosestsSites,
  getClosestsSitesHourlyWashCounts,
  setFromDate,
  setUntilDate,
} from '../actions';
import ClaimsKpiDataGetter from '../components/ClaimsKpiDataGetters';
import CostPerCarDataGetter from '../components/CostPerCarDataGetter';
import GuestCancellationDataGetter from '../components/GuestCancellationDataGetter';
import InstaClubDataGetter from '../components/InstaClubDataGetter';
import RevenueComparisonKpiDataGetter from '../components/RevenueComparisonKpiDataGetter';
import WashCountsChartDataGetter from '../components/WashCountsChartDataGetter';
import { selectClosestHourlyWashCounts, selectFromDate, selectUntilDate } from '../reducer';
import { useIntl } from 'react-intl';
import HourlyWashVisual from '../components/HourlyWashVisual';
import { fetchHourlyWashCountsReport, fetchReport } from '../../analytics/actions';
import { SAFETY_INTERVAL } from '../../analytics/sagas';

export const MyCharts = (): JSX.Element => {
  const dispatch = useDispatch(),
    intl = useIntl(),
    fromDate = useSelector(selectFromDate),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    timeZone = useAppContext().timeZone,
    hourlyWashCounts = useSelector(selectHourlyWashCounts),
    closestHourlyWashCounts = useSelector(selectClosestHourlyWashCounts),
    untilDate = useSelector(selectUntilDate),
    primarySiteId = useSelector(selectSite);

  const twoMonthSelection = {
    name: 'lastTwoMonths',
    displayName: 'Last Two Months',
    from: moment.tz(timeZone).subtract(2, 'months').startOf('month'),
    until: moment.tz(timeZone).subtract(1, 'months').endOf('month'),
  };
  const getHourlyWashCounts = () => {
    dispatch(
      fetchHourlyWashCounts(
        moment(fromDate).format('YYYY-MM-DD'),
        moment(untilDate).format('YYYY-MM-DD')
      )
    );
  };
  const getManagerReport = () => {
    dispatch(fetchHourlyWashCountsReport(SAFETY_INTERVAL, 'Manager-Analytics'));
  };
  useEffect(() => {
    getManagerReport();
  }, [primarySiteId, multiSiteSelection]);
  useEffect(() => {
    getHourlyWashCounts();
  }, [multiSiteSelection, fromDate, untilDate]);
  return (
    <Container fluid className="overview">
      <div className="date-time-overview">
        <DateRangeSelector
          dates={[fromDate, untilDate]}
          onSubmit={(dates) => {
            dispatch(setFromDate(dates.from));
            dispatch(setUntilDate(dates.until));
          }}
          includeCustomSelect={false}
          additionalOptions={[twoMonthSelection]}
          removeItems={['yearToDate', 'lastYearToDate']}
        />
      </div>
      <Row>
        <Col xs={12} lg={6}>
          <UserGroupPermissions itemRef={viewPermissionRefs.wash_count_chart}>
            <WashCountsChartDataGetter
              fromDate={fromDate}
              untilDate={untilDate}
              hourlyWashCounts={hourlyWashCounts}
              closestHourly={closestHourlyWashCounts}
            />
          </UserGroupPermissions>
          <UserGroupPermissions itemRef={viewPermissionRefs.claims_chart}>
            <ClaimsKpiDataGetter fromDate={fromDate} untilDate={untilDate} />
          </UserGroupPermissions>
          <UserGroupPermissions itemRef={viewPermissionRefs.window_sales}>
            <InstaClubDataGetter fromDate={fromDate} untilDate={untilDate} />
          </UserGroupPermissions>
        </Col>
        <Col xs={12} lg={6}>
          <UserGroupPermissions itemRef={viewPermissionRefs.hourly_comparision_chart}>
            <HourlyWashVisual
              visualId={'a3110de7b0e1c3ed0370'}
              pageName={'ReportSectionde220715d8729a0eb06d'}
            />
          </UserGroupPermissions>
          <UserGroupPermissions itemRef={viewPermissionRefs.cost_per_car}>
            <CostPerCarDataGetter fromDate={fromDate} untilDate={untilDate} />
          </UserGroupPermissions>
          <UserGroupPermissions itemRef={viewPermissionRefs.survey_chart}>
            <GuestCancellationDataGetter fromDate={fromDate} untilDate={untilDate} />
          </UserGroupPermissions>
          <UserGroupPermissions itemRef={viewPermissionRefs.revenue_comparison}>
            <RevenueComparisonKpiDataGetter fromDate={fromDate} untilDate={untilDate} />
          </UserGroupPermissions>
        </Col>
      </Row>
    </Container>
  );
};
export default MyCharts;
