import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { selectInventoryTypes } from '../reducer';
import { selectEmployees } from '../../employees/reducer';
import { fetchEmployees } from '../../employees/actions';
import {
  errorInput,
  fieldRequired,
  requiredSelect,
  Select,
  TextArea,
} from '../../../core/formValidation';
import UserGroupPermissions, {
  viewPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { InventoryType } from '../models';

const InventoryEditForm = ({ intl, inventoryType, initialValues, isNew, isEssentialPart }) => {
  const [type, setType] = useState(inventoryType);
  const [essential, setEssential] = useState(
    isEssentialPart || initialValues.priority ? true : false
  );

  const dispatch = useDispatch();
  const inventoryTypes = useSelector(selectInventoryTypes);
  const employees = useSelector(selectEmployees);

  useEffect(() => {
    setType(inventoryType);
  }, [inventoryType]);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);

  return (
    <>
      <p className={`form-alert notice${!essential ? ' hidden' : ''}`}>
        <i className="uil uil-info-circle"></i>
        {isNew
          ? intl.formatMessage({ id: 'essentialInventoryCreateWarning' })
          : intl.formatMessage({ id: 'essentialInventoryUpdateWarning' })}
      </p>
      {isNew && (
        <UserGroupPermissions itemRef={viewPermissionRefs.essential_parts}>
          <div className="form-row">
            <div className="form-check">
              <label className="form-check-labels">
                <Field
                  name="priority"
                  id="priority"
                  className="form-check-input"
                  component="input"
                  type="checkbox"
                  format={(value) => (value === undefined ? essential : value)}
                  onChange={(e) => setEssential(e.target.checked)}
                />
                {intl.formatMessage({ id: 'priority' })}
              </label>
            </div>
          </div>
        </UserGroupPermissions>
      )}
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="type">{intl.formatMessage({ id: 'type' })}</label>
          <Field
            name="inventoryTypeId"
            id="inventoryTypeId"
            component={requiredSelect}
            className="form-control"
            parse={Number}
            onChange={(e) => setType(Number(e.target.value))}
          >
            {inventoryTypes.map((typeOption) => (
              <option value={typeOption.id} key={typeOption.id}>
                {typeOption.name}
              </option>
            ))}
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="name">{intl.formatMessage({ id: 'name' })}</label>
          <Field
            name="name"
            id="name"
            component={errorInput}
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: 'enterPartName' })}
            validate={[fieldRequired]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">{intl.formatMessage({ id: 'partNumber' })}</label>
          <Field
            name="number"
            id="number"
            component={errorInput}
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: 'enterPartNumber' })}
            validate={[fieldRequired]}
          />
        </div>
      </div>
      <div className="form-row full">
        <div className="form-group">
          <label htmlFor="description">{intl.formatMessage({ id: 'description' })}</label>
          <Field
            name="description"
            id="description"
            component={TextArea}
            className="form-control"
            maxLength={400}
            placeholder={intl.formatMessage({ id: 'enterItemDescription' })}
          />
        </div>
      </div>
      {!essential && (
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="type">{intl.formatMessage({ id: 'installedBy' })}</label>
            <Field
              name="employeeId"
              id="employeeId"
              component={Select}
              className="form-control"
              parse={Number}
            >
              <option value="0">{intl.formatMessage({ id: 'selectEmployee' })}</option>
              {employees.map((employee) => (
                <option value={employee.id} key={employee.id}>
                  {`${employee.lastName}, ${employee.firstName}`}
                </option>
              ))}
            </Field>
          </div>
          {type !== InventoryType.Detergent && (
            <React.Fragment>
              <div className="form-group d-flex flex-column">
                <label htmlFor="installedAt" className="mr-2">
                  {intl.formatMessage({ id: 'installedAt' })}
                </label>
                <Field
                  name="installedAt"
                  id="installedAt"
                  component={DateTimePicker}
                  className="form-control"
                  placeholderText={intl.formatMessage({ id: 'selectInstallationDate' })}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      <div className="form-row">
        {!essential && (
          <div className="form-group">
            <label htmlFor="onHand">{intl.formatMessage({ id: 'stockOnHand' })}</label>
            <Field
              name="onHand"
              id="onHand"
              component="input"
              type="number"
              className="form-control"
              parse={Number}
              placeholder={intl.formatMessage({ id: 'enterStockOnHand' })}
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="preferredStockLevel">
            {intl.formatMessage({ id: 'preferredStockLevel' })}
          </label>
          <Field
            name="preferredStockLevel"
            id="preferredStockLevel"
            component="input"
            min="1"
            step="1"
            type="number"
            className="form-control"
            parse={Number}
            placeholder={intl.formatMessage({ id: 'enterPreferredStockLevel' })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="unitOfMeasure">{intl.formatMessage({ id: 'units' })}</label>
          <Field name="unitOfMeasure" id="unitOfMeasure" component={Select}>
            <option value="Number">{intl.formatMessage({ id: 'number' })}</option>
            <option value="Gallons">{intl.formatMessage({ id: 'gallons' })}</option>
            <option value="Feet">{intl.formatMessage({ id: 'feet' })}</option>
          </Field>
        </div>
      </div>
      <div className="form-row full">
        <div className="form-group">
          <label htmlFor="preferredStockLevel">{intl.formatMessage({ id: 'url' })}</label>
          <Field
            name="vendorUrl"
            id="vendorUrl"
            component="input"
            type="string"
            className="form-control"
            placeholder={intl.formatMessage({ id: 'enterVendorLink' })}
          />
        </div>
      </div>
    </>
  );
};

InventoryEditForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  inventoryType: PropTypes.number,
};

const enhance = compose(reduxForm({ form: 'inventory' }), injectIntl);

export default enhance(InventoryEditForm);
