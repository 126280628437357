import * as api from '../api';

export const fetchReport = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/sites/${siteId}/azure/report`, {
    ...args,
    data: { query: args?.data?.query },
  });
};
export const fetchHourlyWashCountsReport = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/sites/${siteId}/azure/dashboard/tiles`, {
    ...args,
    data: { query: args?.data?.query },
  });
};
