import { find, keys, merge } from 'lodash';
import { cancel, select, takeEvery } from 'redux-saga/effects';
import { API_REQUEST } from '../core/actions';
import { apiSaga, getRange, notifyNetworkError } from '../core/api/utils';
import { hasAllAccess } from '../core/auth';
import { apiError } from '../core/toastMessages';
import { selectUserGroup } from '../store/authReducer';
import { selectDateRangeLimiter, selectDateRangeLimiterEnabled } from '../views/settings/reducer';
import {
  selectMultiSiteSelectionCoreIds,
  selectMultiSiteSelectionManagerIds,
  selectClosestManagerIds,
} from '../views/site-selection/reducer';

function* apiPermissionCheck(opts) {
  let userGroup = yield select(selectUserGroup);
  if (opts?.meta?.permissionKey) {
    const hasPermission = find(userGroup.permissions, (ugp) => ugp.ref === opts.meta.permissionKey);
    if (hasAllAccess(userGroup) || hasPermission) {
      return yield* applyAdditionalSites(opts);
    } else {
      return yield cancel();
    }
  }

  yield* applyAdditionalSites(opts);
}

function* applyAdditionalSites(opts) {
  let additionalSiteIds = [];
  if (opts?.meta?.useManagerSiteId) {
    additionalSiteIds = yield select(selectMultiSiteSelectionManagerIds);
  } else if (opts?.meta?.useClosestSiteIds) {
    additionalSiteIds = yield select(selectClosestManagerIds);
  } else {
    additionalSiteIds = yield select(selectMultiSiteSelectionCoreIds);
  }

  if (additionalSiteIds.length > 4) {
    yield* notifyNetworkError({
      name: 'Exceeded Site Selection Limit',
      data: 'Four Additional Sites Allowed',
    });
    return yield cancel();
  }

  if (keys(opts.data).length) {
    if (opts.data.query) {
      merge(opts.data.query, { additionalSiteIds });
    }
  }

  yield* dateRangeLimiterCheck(opts);
}

function* dateRangeLimiterCheck(opts) {
  const isDateRangeLimiterEnabled = yield select(selectDateRangeLimiterEnabled);
  if (isDateRangeLimiterEnabled) {
    let dateRangeLimiter = yield select(selectDateRangeLimiter);
    if (opts?.data?.query) {
      if (opts.meta?.dateRangeException) {
        opts.data.query = {
          ...opts.data.query,
          range: dateRangeLimiter.range,
          dateType: dateRangeLimiter.type,
        };
      }
    }
  }

  yield* callApi(opts);
}

export function* callApi({ callAPI, data, action, ...rest }) {
  yield* apiSaga(callAPI, data, action, rest);
}

// eslint-disable-next-line
export function* watchApiSagas() {
  yield takeEvery(API_REQUEST, apiPermissionCheck);
}
