import React, { useRef, useEffect, useState } from 'react';
import {
  models,
  service,
  factories,
  IEmbedConfiguration,
  Visual,
  IVisualEmbedConfiguration,
} from 'powerbi-client';

interface VisualEmbedProps {
  accessToken: string;
  embedUrl: string;
  reportId: string;
  pageName: string;
  visualId: string;
}

const VisualEmbed: React.FC<VisualEmbedProps> = ({
  accessToken,
  embedUrl,
  reportId,
  pageName,
  visualId,
}) => {
  const [visual, setVisual] = useState<Visual | null>(null);
  const embedContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const embedConfig: IVisualEmbedConfiguration = {
      type: 'visual',
      width: 350,
      height: 200,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: reportId,
      pageName: pageName,
      visualName: visualId,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: models.LayoutType.Master,
      },
    };

    if (embedContainer.current) {
      const powerbiService = new service.Service(
        factories.hpmFactory,
        factories.wpmpFactory,
        factories.routerFactory
      );

      const visualComponent = powerbiService.embed(embedContainer.current, embedConfig) as Visual;
      visualComponent.on('loaded', () => {
        setVisual(visualComponent);
      });
      visualComponent.on('error', (event) => {
        console.error('Error embedding visual', event.detail);
      });

      return () => {
        if (embedContainer.current) {
          powerbiService.reset(embedContainer.current!);
        }
      };
    }
  }, []);

  return (
    <div ref={embedContainer} style={{ width: '100%', maxHeight: '600px', aspectRatio: '16/9' }} />
  );
};

export default VisualEmbed;
