import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchOperatorGroup, fetchSiteSettings } from '../core/actions';
import {
  selectAnalyticsEnabled,
  selectRole,
  selectUserGroupDynamicPermissions,
} from '../store/authReducer';
import { fetchReport, fetchHourlyWashCountsReport } from '../views/analytics/actions';
import { selectEmbedToken } from '../views/analytics/reducer';
import { setSelectedSite } from '../views/site-selection/actions';
import { SAFETY_INTERVAL } from '../views/analytics/sagas';

interface Props {
  children: React.ReactNode;
}

const SiteDispatcher = ({ children }: Props) => {
  const { site } = useParams<{ site: string }>();
  const dispatch = useDispatch();
  const token = useSelector((state) => selectEmbedToken(state, 'Manager-Analytics'));
  const analyticsEnabled = useSelector(selectAnalyticsEnabled);
  const isAdmin = useSelector(selectRole);
  const analyticsDynamicPermissions = useSelector(selectUserGroupDynamicPermissions);
  const history = useHistory();

  useEffect(() => {
    if (site) {
      dispatch(setSelectedSite({ id: site }));
    }
    if (!token) {
      dispatch(fetchSiteSettings(history, '/site-selection'));

      if (
        isAdmin ||
        analyticsEnabled ||
        (Array.isArray(analyticsDynamicPermissions) &&
          analyticsDynamicPermissions.some((permission) =>
            permission.ref.includes('analytics_tabs')
          ))
      ) {
        dispatch(fetchReport(SAFETY_INTERVAL, 'Manager-Analytics'));
        //Currently used for testing dashboard tiles
        //dispatch(fetchHourlyWashCountsReport(SAFETY_INTERVAL, 'Tile-Testing'));
      }
    }
    dispatch(fetchOperatorGroup());
  }, []);

  return children;
};

export default SiteDispatcher;
