import Select, { components } from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchSites } from '../../../core/actions';
import { generateToastr } from '../../../core/toastMessages';
import { getMultiSites, removeMultiSites, setMultiSites } from '../../../core/storage';
import { selectSite } from '../../../store/authReducer';
import {
  setAndStoreSelectedSite,
  setMultiSiteSelection,
  setPrimarySite,
  updateTimeZone,
} from '../actions';
import { selectSitesBasic } from '../reducer';
import { getApiStatus } from '../../../core/utils';
import { useIntl } from 'react-intl';
import { selectSiteSettingsSite } from '../../settings/reducer';
import { Site } from '../models';

const SiteSelector = (): JSX.Element => {
  const [selectedSites, setSelectedSites] = useState<any[]>([]);
  const [selectorScrolling, setSelectorScrolling] = useState<boolean>(false);
  const storedMultiSites: any = getMultiSites();
  const sites = useSelector(selectSitesBasic, shallowEqual);
  const currentSite: Site = useSelector(selectSiteSettingsSite, shallowEqual);
  const primarySiteId = useSelector(selectSite);
  const pathNameRegex = RegExp(/[a-zA-Z]+/g);
  const currentPage: string[] = pathNameRegex.exec(window.location.pathname) || ['overview'];
  const isLoadingSites = useSelector((state) => getApiStatus(state, 'FETCH_SITES'));
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [placeholderText, setPlaceholder] = useState(
    `${intl.formatMessage({ id: 'loadingSites' })}...`
  );
  const siteLimit = 5; //includes the primary site

  //Format the site data to be used by the Select component (add additional properties and sort)
  const formattedSites = useMemo(() => {
    return sites
      .map((site: any) => ({
        ...site,
        value: site.id,
        label: site.externalId ? site.externalId + ' (' + site.name + ')' : site.name,
        chipLabel: site.externalId ? site.externalId : site.name,
      }))
      .sort(function (a: any, b: any) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  }, [sites]);

  const singleSite = (id: string) => {
    return formattedSites.filter((site: any) => {
      return site.value == parseInt(id);
    });
  };

  const getSites = () => {
    dispatch(fetchSites());
  };

  const setSites = () => {
    if (sites?.length > 0) {
      if (storedMultiSites) {
        const siteObj = JSON.parse(storedMultiSites);
        siteObj.unshift(singleSite(primarySiteId)[0]);
        setSelectedSites(siteObj);
      } else {
        setSelectedSites(singleSite(primarySiteId));
      }
      updateSitePlaceholder();
    }
  };

  useEffect(getSites, []);
  useEffect(setSites, [sites, primarySiteId]);

  const updateSitePlaceholder = () => {
    if (isLoadingSites) {
      setPlaceholder(`${intl.formatMessage({ id: 'loadingSites' })}...`);
    } else {
      if (sites.length > 0) {
        setPlaceholder(intl.formatMessage({ id: 'noSitesSelected' }));
      } else {
        setPlaceholder(intl.formatMessage({ id: 'noSitesAvailable' }));
      }
    }
  };

  //Fired on TouchMove event to prevent primary site selection when mobile scrolling
  const handleTouchScroll = (e: any) => {
    setSelectorScrolling(true);
  };

  //Called when one of the multiSite values is clicked
  const onPrimarySiteClick = (e: any, site: any) => {
    if (!selectorScrolling) {
      e.preventDefault();
      e.stopPropagation();
      if (site.id != parseInt(primarySiteId)) {
        //Calling onSiteChange on the component to put the new Primary site in the first position
        onSiteChange(
          selectedSites.sort((x: any, y: any) => {
            return x.id == site.id ? -1 : y.id == site.id ? 1 : 0;
          })
        );
        updatePrimarySite(site);
      }
    }
    setSelectorScrolling(false);
  };

  //Does the work of actually updating primary site and notifying
  const updatePrimarySite = (site: any) => {
    if (site.id != parseInt(primarySiteId)) {
      //Logic to update primary site in state / storage
      dispatch(updateTimeZone({ settings: { site: { ...currentSite } } }));
      dispatch(setAndStoreSelectedSite({ id: site.id }));
      dispatch(setPrimarySite(site));
      history.push(`/${site.id}/${currentPage[0]}`);
      //Notify user of the change
      generateToastr(
        'success',
        intl.formatMessage({ id: 'newSiteSelected' }),
        `${site.externalId ? site.externalId : site.name}`,
        {
          hideDuration: 3000,
          transitionIn: 'fadeIn',
        }
      );
    }
  };

  const applyMultiSiteSelection = (siteValues: any[]) => {
    if (siteValues.length > 1) {
      //Remove the first item and save values to state / storage
      setMultiSites(siteValues.slice(1));
      dispatch(setMultiSiteSelection(siteValues.slice(1)));
    } else {
      //Clear multiSite state and storage
      dispatch(setMultiSiteSelection([]));
      removeMultiSites();
    }
  };

  //Custom theme object for the react-select component
  const theme = (basetheme: any) => ({
    ...basetheme,
    colors: {
      ...basetheme.colors,
      primary25: '#f0f0f0',
      primary: '#be1f24',
    },
  });

  //Custom chip component to display just the site id, rather than the entire label (site id + name)
  const MultiValueLabel = (props: any) => (
    <div
      data-testid="multi-value-label"
      onClick={(e: any) => onPrimarySiteClick(e, props.data)}
      onTouchMove={(e: any) => handleTouchScroll(e)}
      onTouchEnd={(e: any) => onPrimarySiteClick(e, props.data)}
      title={props.data.chipLabel}
    >
      <components.MultiValueLabel {...props}>{props.data.chipLabel}</components.MultiValueLabel>
    </div>
  );

  //Main "onChange" event listener for the select component
  const onSiteChange = (value: any) => {
    if (value.length > 0) {
      updatePrimarySite(value[0]);
    } else {
      setPlaceholder(
        `${
          currentSite?.externalId ? currentSite.externalId : currentSite.name
        } ${intl.formatMessage({ id: 'isStillActive' })}`
      );
      generateToastr(
        'info',
        `${
          currentSite?.externalId ? currentSite.externalId : currentSite.name
        } ${intl.formatMessage({ id: 'isStillActive' })}`,
        `${intl.formatMessage({ id: 'oneSite' })}`,
        {
          timeOut: 5500,
          transitionIn: 'fadeIn',
        }
      );
    }
    setSelectedSites(value);
    applyMultiSiteSelection(value);
  };

  return (
    <span data-testid="custom-site-select">
      <Select
        className="custom-tx-select"
        classNamePrefix="custom-tx"
        isMulti={true}
        value={selectedSites}
        options={formattedSites}
        closeMenuOnSelect={false}
        components={{ MultiValueLabel }}
        onChange={(value: any) => onSiteChange(value)}
        isOptionDisabled={() => selectedSites.length >= siteLimit}
        isSearchable={true}
        hideSelectedOptions={true}
        blurInputOnSelect={true}
        //isDisabled={isLoadingSites}
        isClearable={false}
        theme={theme}
        placeholder={placeholderText}
      />
    </span>
  );
};

export default SiteSelector;
