import { sumBy } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../core/components/Loader';
import { useAppContext } from '../../../providers/AppProvider';
import { setFromDate, setUntilDate } from '../actions';
import { selectFromDate } from '../reducer';

interface Props {
  hourlyWashCounts: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[];
  washCounts: { date: moment.Moment; count: number }[];
}

const DailyWashCountKpi = (props: Props) => {
  const fromDate = useSelector(selectFromDate);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentCount, setCurrentSelectionCount] = useState(0);
  const addAllHourlyCounts = (): void => {
    const totalCounts: number[] = [];
    for (const [key, value] of Object.entries(props.hourlyWashCounts)) {
      totalCounts.push(value.count);
    }
    setCurrentSelectionCount(sumBy(totalCounts));
  };
  const tz = useAppContext().timeZone;
  const changeDateRange = (date: Moment) => {
    const setTime = moment.tz(date, tz).format('YYYY-MM-DD');

    dispatch(setFromDate(moment(moment(setTime)).startOf('day')));
    dispatch(setUntilDate(moment(moment(setTime)).endOf('day')));
  };

  const dailyWashCountCards = () => {
    return props.washCounts ? (
      <React.Fragment>
        {props.washCounts
          .sort((a, b) => moment(a.date).diff(moment(b.date)))
          .map((washCount, i) => (
            <button
              key={i}
              className="col-md-2 col-sm-12 washed-card cars-washed"
              onClick={() => changeDateRange(washCount.date)}
            >
              <div className="daily-numbers">{washCount.count}</div>
              {moment(washCount.date).format('ddd, M/D')}
            </button>
          ))}
      </React.Fragment>
    ) : (
      <div className="powerbi-loading">
        <Loader />
      </div>
    );
  };

  useEffect(addAllHourlyCounts, [fromDate]);

  return (
    <section className="row-flex-inherit">
      {dailyWashCountCards()}
      <div className="col-md-2 col-sm-12 washed-card cars-washed-selected">
        <div data-testid="overview-hourly-total" className="daily-numbers">
          {currentCount}
        </div>
        {intl.formatMessage({ id: 'selectedTotal' })}
      </div>
    </section>
  );
};

export default DailyWashCountKpi;
