import { useEffect, useState } from 'react';

const InventoryStockLevel = (props: any) => {
  const { rowData, mixIns } = props;
  const [value, setValue] = useState(rowData._onHand);
  const [valid, setValid] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.reportValidity()) {
      mixIns.updateStock(rowData._id, Number(e.target.value));
    } else {
      mixIns.clearStock(rowData._id);
      setValid(false);
    }
  };

  const revertValue = () => {
    if (!valid) {
      setValue(rowData._onHand);
      setValid(true);
    }
  };

  const stockPercentageColor = () => {
    if (rowData._preferredStockLevel && rowData._preferredStockLevel > 0) {
      const percentage = rowData._onHand / rowData._preferredStockLevel;
      if (percentage > 0.8) {
        return 'green';
      } else if (percentage > 0.4) {
        return 'yellow';
      } else {
        return 'red';
      }
    }
    return '';
  };

  useEffect(() => {
    if (!mixIns.isEditing) {
      setValue(rowData._onHand);
      setValid(true);
    }
  }, [mixIns.isEditing, rowData._onHand]);

  return (
    <div className="stock-input-container">
      {mixIns.isEditing ? (
        <>
          <input
            type="number"
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={() => {
              revertValue();
            }}
            value={value}
            min="0"
            step="1"
            className={`table-input ${!valid ? ' invalid' : ''}`}
            data-testid="inventory-stock-input"
            required
          />
          {rowData._preferredStockLevel && (
            <span className="preferred-stock">/{rowData._preferredStockLevel}</span>
          )}
        </>
      ) : (
        <>
          <span className={`indicator ${stockPercentageColor()}`}>
            <span>{value}</span>
            {rowData._preferredStockLevel != null && <span> /{rowData._preferredStockLevel}</span>}
          </span>
        </>
      )}
    </div>
  );
};

export default InventoryStockLevel;
