import moment from 'moment';
import { useState, useEffect, useRef, Fragment } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getApiStatus } from '../utils';
import { selectSite, selectAlerts } from '../../store/authReducer';
import { selectMultiSiteSelection } from '../../views/site-selection/reducer';
import {
  acknowledgeAlert,
  acknowledgeAllAlerts,
  fetchAlerts,
} from '../../views/site-selection/actions';
import { useIntl } from 'react-intl';
import { Alert } from './Alert';
import ToggleButton from './ToggleButton';
import Loader from './Loader';

export interface AlertObject {
  id: number;
  createdAt: string;
  name: string;
  description: string;
  siteId: number;
  detergentId: number;
  type: number;
  acknowledged: boolean;
}

export const Alerts = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<number | null>(null);
  const [filteringRead, setFilteringRead] = useState(false);
  const [filteredAlerts, setfilteredAlerts] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const alerts = useSelector(selectAlerts),
    primarySiteId = useSelector(selectSite),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    loadingApiCall = useSelector((state) => getApiStatus(state, 'FETCH_ALERTS'));

  const alertTypes: any = {
    Detergents: 0,
    Tasks: 1,
    Inventory: 2,
  };

  useEffect(() => {
    const sortedArray = filterAlerts(selectedType);
    setfilteredAlerts(sortedArray);
    setUnreadCount(getUnreadCount(alerts));
  }, [alerts, selectedType, filteringRead]);

  const getSiteNotifications = () => {
    dispatch(fetchAlerts());
  };

  const getUnreadCount = (alertList: AlertObject[]) => {
    if (alertList?.length) {
      return alertList.filter((a: AlertObject) => a.acknowledged == false).length;
    }
    return 0;
  };

  const filterAlerts = (type: number | null) => {
    if (alerts) {
      //If filtering by type, return filtered list
      if (type != null) {
        if (filteringRead) {
          return alerts.filter((alert: AlertObject) => alert.type == type && !alert.acknowledged);
        }
        return alerts.filter((alert: AlertObject) => alert.type == type);
      } else {
        if (filteringRead) {
          return alerts.filter((alert: AlertObject) => !alert.acknowledged);
        }
        return alerts;
      }
    } else {
      return [];
    }
  };

  const handleNotificationList = () => {
    return (
      <div className="alerts-list">
        {filteredAlerts.map((n: AlertObject, i: any) => (
          <Alert alert={n} key={i} />
        ))}
      </div>
    );
  };

  const alertTypeButton = (typeLabel: string, typeValue: number | null) => {
    return (
      <button
        onClick={() => setSelectedType(typeValue)}
        className={`${selectedType == typeValue ? 'active' : ''}`}
      >
        {typeLabel}
      </button>
    );
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleFilterToggle = (value: boolean) => {
    setFilteringRead(value);
  };

  useEffect(getSiteNotifications, [primarySiteId, multiSiteSelection]);

  return (
    <div className="alert-container">
      {unreadCount > 0 ? (
        <span className="alert-number" data-testid="alerts-number">
          {unreadCount}
        </span>
      ) : (
        ''
      )}
      <Dropdown className="n-main-dropdown" isOpen={open} toggle={toggleOpen}>
        <DropdownToggle
          className={`btn btn-secondary alert-button${unreadCount > 0 ? ' has-alerts' : ''}`}
        >
          <i className="icon icon-bell"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" right>
          <div className="alerts-content" data-testid="alerts-dropdown-content">
            <div className="alerts-title">
              <p>{intl.formatMessage({ id: 'notifications' })}</p>
              <ToggleButton
                label="Only show unread"
                initialState={filteringRead}
                toggleCallback={handleFilterToggle}
                leftLabel={true}
              />
            </div>
            <ul className="alert-categories">
              <li>{alertTypeButton('All', null)}</li>
              {Object.entries(alertTypes).map((type: any[], i: any) => (
                <li key={i}>{alertTypeButton(type[0], type[1])}</li>
              ))}
            </ul>
            {filteredAlerts.length ? (
              <div>{handleNotificationList()}</div>
            ) : (
              <p className="alerts-message" role="menuitem">
                {intl.formatMessage({ id: 'noAlerts' })}
              </p>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Alerts;
